<template>
  <div class="page-disclaimer animate__animated animate__fadeIn pt-4">
    <div class="text-center mt-4" v-if="!showDescription">
      <p class="fs-sm mb-0">Tap to see how much time & money you're saving</p>
      <video
        :src="require('@/assets/tap-to-save.mp4')"
        class="img-fluid mt-3 mb-4 tap-here-animation"
        autoplay
        muted
        loop
      ></video>

      <img
        :src="require('@/assets/banners/banner-disclaimer-page.png')"
        class="img-to-tp img-fluid mb-5 c-pointer animate__animated animate__shakeY animate__slow animate__repeat-1"
        alt=""
        @click="showConfetti()"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('banner');
          }
        "
      />

      <div id="banner" class="text-center my-5">
        <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
      </div>
      <!-- <h4 class="text-center fs-xl mb-5">Your time is valuable</h4> -->
    </div>

    <div
      class="sub-description text-center pt-4 animate__animated animate__fadeIn"
      v-if="showDescription"
    >
      <img
        :src="require('@/assets/banners/disclaimer.png')"
        class="img-fluid mb-3"
        style="width: 85%; border-radius: 120px; display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('disclaimer');
          }
        "
      />

      <div id="disclaimer" class="text-center my-5">
        <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
      </div>

      <h4 class="fs-xxl px-2">
        {{ this.firstName }}, based on your updates you'll save over
        <span>{{ timeSaved }} hours</span> and over <span>£{{ moneySaved.toFixed(2) }}</span> in
        your time.
      </h4>

      <!-- <div class="d-flex align-items-center justify-content-center" style="margin-top: 175px">
          <div class="d-flex">
            <div class="label-done px-2">
              <p class="mb-0 text-black fw-bold">You've also won a free reward 🎁</p>
            </div>
          </div>
          <i class="ms-2 fa-solid fa-circle-info"></i>
        </div> -->
    </div>

    <div
      v-if="showDescription"
      class="label-pages d-flex p-2 px-3 align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <img :src="require('@/assets/gift.png')" width="30px" class="ms-2" alt="" />
        <p class="ms-3 fs-sm mb-0 text-start">
          Excellent, now let's summarise your move and complete your updates
        </p>
      </div>

      <!-- <i class="ms-2 fa-solid fa-circle-info"></i> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import VueConfetti from "vue-confetti";
import { navigateTo } from "../router/index.js";

Vue.use(VueConfetti);
// @ is an alias to /src

export default {
  name: "PageDisclaimer",
  components: {},
  data() {
    return {
      showDescription: false,
      // timeSaved: 0,
      // moneySaved: 0,
      textWait: true,
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    selectService(informName, value) {
      this.$store.commit("formStore/setField", [`${informName}`, !value]);
    },
    showConfetti() {
      //Display message and initialize confetti
      this.showDescription = true;
      this.$confetti.start({
        particles: [
          {
            type: "rect",
          },
          {
            type: "circle",
          },
        ],
        defaultDropRate: 12,
        defaultSize: 10,
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 2500);
    },
    // calculateTimeMoneySaved() {
    //   let services = [
    //     { NHS: { time: 1, money: 16.14 } },
    //     { DVLA: { time: 2.5, money: 40.35 } },
    //     { LOYALTY: { time: 0.5, money: 8.07 } } /* {per} */,
    //     { SLC: { time: 0.5, money: 8.07 } },
    //     { BANKS: { time: 1, money: 16.14 } } /* {per} */,
    //     { ELECTORAL: { time: 1, money: 16.14 } },
    //     { HMRC: { time: 2, money: 32.28 } },
    //     { CHARITY: { time: 0.5, money: 8.07 } } /* {per} */,
    //     { BONDS: { time: 1, money: 16.14 } },
    //     { TVLICENSE: { time: 1, money: 16.14 } },
    //     { LOTTERY: { time: 1, money: 16.14 } },
    //     { PENSION: { time: 1, money: 16.14 } } /* {per} */,
    //     { ENERGY: { time: 1, money: 16.14 } },
    //     { GYMS: { time: 0.5, money: 8.07 } } /* {per} */,
    //     { DWP: { time: 1.5, money: 24.21 } },
    //     { MOBILE: { time: 0.5, money: 8.07 } },
    //     { COUNCIL: { time: 1.5, money: 24.21 } },
    //     { WATER: { time: 1, money: 16.14 } },
    //   ];

    //   const setTimeMoney = (timeService, moneyService) => {
    //     this.timeSaved += timeService;
    //     this.moneySaved += timeService * moneyService;

    //   };

    //   services.forEach((serv) => {
    //     if (serv.hasOwnProperty("NHS") && this.informNHS) {
    //       setTimeMoney(serv.NHS["time"], serv.NHS["money"]);
    //     }

    //     if (serv.hasOwnProperty("DVLA") && this.informDVLA) {
    //       setTimeMoney(serv.DVLA["time"], serv.DVLA["money"]);
    //     }

    //     if (serv.hasOwnProperty("LOYALTY") && this.informLoyalty) {
    //       setTimeMoney(serv.LOYALTY["time"] * this.selectedLoyalties.length, serv.LOYALTY["money"]);
    //     }

    //     if (serv.hasOwnProperty("SLC") && this.informStudentFinance) {
    //       setTimeMoney(serv.SLC["time"], serv.SLC["money"]);
    //     }

    //     if (serv.hasOwnProperty("BANKS") && this.informBanks) {
    //       setTimeMoney(serv.BANKS["time"] * this.selectedBanks.length, serv.BANKS["money"]);
    //     }

    //     if (serv.hasOwnProperty("ELECTORAL") && this.informElectoralRoll) {
    //       setTimeMoney(serv.ELECTORAL["time"], serv.ELECTORAL["money"]);
    //     }

    //     if (serv.hasOwnProperty("HMRC") && this.informHMRC) {
    //       setTimeMoney(serv.HMRC["time"], serv.HMRC["money"]);
    //     }

    //     if (serv.hasOwnProperty("CHARITY") && this.informCharities) {
    //       setTimeMoney(serv.CHARITY["time"] * this.selectedCharities.length, serv.CHARITY["money"]);
    //     }

    //     if (serv.hasOwnProperty("BONDS") && this.informBonds) {
    //       setTimeMoney(serv.BONDS["time"], serv.BONDS["money"]);
    //     }

    //     if (serv.hasOwnProperty("TVLICENSE") && this.informTVLicense) {
    //       setTimeMoney(serv.TVLICENSE["time"], serv.TVLICENSE["money"]);
    //     }

    //     if (serv.hasOwnProperty("LOTTERY") && this.informLottery) {
    //       setTimeMoney(serv.LOTTERY["time"], serv.LOTTERY["money"]);
    //     }

    //     if (serv.hasOwnProperty("PENSION") && this.informPension) {
    //       setTimeMoney(serv.PENSION["time"] * this.pensionSelection.length, serv.PENSION["money"]);
    //     }

    //     if (serv.hasOwnProperty("ENERGY") && this.informEnergy) {
    //       setTimeMoney(serv.ENERGY["time"], serv.ENERGY["money"]);
    //     }

    //     if (serv.hasOwnProperty("GYMS") && this.informGyms) {
    //       setTimeMoney(serv.GYMS["time"] * this.selectedGyms.length, serv.GYMS["money"]);
    //     }

    //     if (serv.hasOwnProperty("DWP") && this.informDWP) {
    //       setTimeMoney(serv.DWP["time"], serv.DWP["money"]);
    //     }

    //     if (serv.hasOwnProperty("MOBILE") && this.informMobile) {
    //       setTimeMoney(serv.MOBILE["time"] * this.mobileSelection.length, serv.MOBILE["money"]);
    //     }

    //     if (serv.hasOwnProperty("COUNCIL") && this.informNewCouncil) {
    //       setTimeMoney(serv.COUNCIL["time"], serv.COUNCIL["money"]);
    //     }

    //     if (serv.hasOwnProperty("WATER") && this.informNewWater) {
    //       setTimeMoney(serv.WATER["time"], serv.WATER["money"]);
    //     }
    //   });
    // },
    calculatePricing() {
      // If Selects only one service of the below: £10
      // let items10Pounds = {
      //   informDWP: this.informDWP,
      //   informHMRC: this.informHMRC,
      // };

      // // If Selects only one service of the below (anything else): £5
      // let items5Pounds = {
      //   informDVLA: this.informDVLA,
      //   informNHS: this.informNHS,
      //   informBanks: this.informBanks,
      //   informElectoralRoll: this.informElectoralRoll,
      //   informMobile: this.informMobile,
      //   informDentist: this.informDentist,
      //   informBonds: this.informBonds,
      //   informGyms: this.informGyms,
      //   informPension: this.informPension,
      //   informCharities: this.informCharities,
      //   informLottery: this.informLottery,
      //   informEnergy: this.informEnergy,
      //   informTVLicense: this.informTVLicense,
      //   informNewCouncil: this.informNewCouncil,
      //   informNewWater: this.informNewWater,
      //   informOldCouncil: this.informOldCouncil,
      //   informOldWater: this.informOldWater,
      // };

      // if (Object.values(items10Pounds).includes(true)) {
      //   this.$store.commit("formStore/setField", [`priceOffered`, 10]);
      // }

      // if (Object.values(items5Pounds).includes(true)) {
      //   this.$store.commit("formStore/setField", [`priceOffered`, 5]);
      // }

      //else if they select more than one service then £20
      // let allServices = { ...items5Pounds, ...items10Pounds };

      let allServices = [
        this.informDWP,
        this.informHMRC,
        this.informDVLA,
        this.informNHS,
        this.informElectoralRoll,
        this.informDentist,
        this.informBonds,
        this.informLottery,
        this.informEnergy,
        this.informTVLicense,
        this.informNewCouncil,
        this.informNewWater,
        this.informOldCouncil,
        this.informOldWater,
        ...this.pensionSelection.map((item) => item.name),
        ...this.mobileSelection.map((item) => item.name),
        ...this.selectedBanks.map((item) => item.name),
        ...this.selectedGyms.map((item) => item.name),
        ...this.selectedCharities.map((item) => item.name),
        ...this.selectedLoyalties.map((item) => item.name),
      ];

      let quantityTrue = 0;
      let quantityFalse = 0;
      // console.log(Object.values(allServices));

      Object.values(allServices).forEach((service) => {
        if (service) ++quantityTrue;
        if (!service) ++quantityFalse;
      });


      // if (quantityTrue == 1) {
      //   this.$store.commit("formStore/setField", [`priceOffered`, 5]);
      // }

      // if (quantityTrue == 2) {
      //   this.$store.commit("formStore/setField", [`priceOffered`, 10]);
      // }

      // if (quantityTrue >= 3) {
      //   this.$store.commit("formStore/setField", [`priceOffered`, 20]);
      // }

      //Partners
      if (this.referrer == "Perx") {
        this.$store.commit("formStore/setField", ["priceOffered", 18]);
      }

      if (this.referrer == "MovingHub") {
        this.$store.commit("formStore/setField", ["priceOffered", 25]);
      }

    },
  },
  mounted() {
    this.calculatePricing();
    // this.calculateTimeMoneySaved();
    this.$store.commit("setField", ["titleNavbar", `${this.firstName}'s move`]);

    this.$store.commit("setField", ["step1", true]);

    const validatePage = () => {
      let route = "/review";

      navigateTo(route);
    };

    document.getElementById("mainButton").onclick = (ev) => {
      this.$confetti.stop();
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$confetti.stop();
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        this.$confetti.stop();
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "firstName",
      "baseURL",
      "urlBucket",
      "informLoyalty",
      "informStudentFinance",
      "informNHS",
      "informDVLA",
      "informBanks",
      "informElectoralRoll",
      "informHMRC",
      "informMobile",
      "informDentist",
      "informBonds",
      "informGyms",
      "informDWP",
      "informPension",
      "informCharities",
      "informLottery",
      "informEnergy",
      "informEnergy",
      "informTVLicense",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      //Selections - arrays
      "pensionSelection",
      "mobileSelection",
      "selectedBanks",
      "selectedGyms",
      "selectedCharities",
      "selectedLoyalties",
      //Money Time saved
      "timeSaved",
      "moneySaved",
    ]),
  },
  watch:{
    timeSaved(val){
      this.$store.commit("formStore/setField", [`timeSaved`, val]);
    }
  }
};
</script>
<style lang="scss">
.page-disclaimer {
  /*height: 67vh;*/
  .tap-here-animation {
    border-radius: 120px;
    transform: rotate(180deg);
    width: 26px;
  }

  .sub-description {
    h4 {
      margin-top: 2.5rem;
    }
    span {
      color: #ffe859;
    }
  }

  .label-pages {
    margin-top: 7rem;
    @media screen and (max-width: 600px) {
      margin-top: 20vw;
    }
  }
}
</style>
