var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-disclaimer animate__animated animate__fadeIn pt-4"},[(!_vm.showDescription)?_c('div',{staticClass:"text-center mt-4"},[_c('p',{staticClass:"fs-sm mb-0"},[_vm._v("Tap to see how much time & money you're saving")]),_c('video',{staticClass:"img-fluid mt-3 mb-4 tap-here-animation",attrs:{"src":require('@/assets/tap-to-save.mp4'),"autoplay":"","muted":"","loop":""},domProps:{"muted":true}}),_c('img',{staticClass:"img-to-tp img-fluid mb-5 c-pointer animate__animated animate__shakeY animate__slow animate__repeat-1",staticStyle:{"display":"none"},attrs:{"src":require('@/assets/banners/banner-disclaimer-page.png'),"alt":""},on:{"click":function($event){return _vm.showConfetti()},"load":(e) => {
          e.target.style.display = 'unset';
          _vm.hideSpinner('banner');
        }}}),_vm._m(0)]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"sub-description text-center pt-4 animate__animated animate__fadeIn"},[_c('img',{staticClass:"img-fluid mb-3",staticStyle:{"width":"85%","border-radius":"120px","display":"none"},attrs:{"src":require('@/assets/banners/disclaimer.png')},on:{"load":(e) => {
          e.target.style.display = 'unset';
          _vm.hideSpinner('disclaimer');
        }}}),_vm._m(1),_c('h4',{staticClass:"fs-xxl px-2"},[_vm._v(" "+_vm._s(this.firstName)+", based on your updates you'll save over "),_c('span',[_vm._v(_vm._s(_vm.timeSaved)+" hours")]),_vm._v(" and over "),_c('span',[_vm._v("£"+_vm._s(_vm.moneySaved.toFixed(2)))]),_vm._v(" in your time. ")])]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"label-pages d-flex p-2 px-3 align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"ms-2",attrs:{"src":require('@/assets/gift.png'),"width":"30px","alt":""}}),_c('p',{staticClass:"ms-3 fs-sm mb-0 text-start"},[_vm._v(" Excellent, now let's summarise your move and complete your updates ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"banner"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"disclaimer"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
}]

export { render, staticRenderFns }